<template>
    <div  class="mt-5 mb-4 px-0 fondo-blanco" v-if="tipo.MetodoPago==='BANKNACION_CHEQUE'" >
      <v-row
        class="px-2 mx-0"
        style="border-bottom: 1px solid #ddd"
      >
        <v-col
          cols="auto"
          style="width: 5%"
          class="px-0 ml-0 py-0 mt-3"
        >
          <v-btn
            x-small
            class="btn-panel-mas-menos"
            @click="toggleCardItemCheque(tipo.TipoElementosId)"
            style="background: #444; padding: 0px"

          >
            <v-icon
              style="font-size: 0.8rem; margin: 0px; padding: 0px"
            >
              {{
                cardStatesItemCheque[tipo.TipoElementosId] ? "mdi-minus" : "mdi-plus"
              }}
            </v-icon>
          </v-btn>
        </v-col>
        <v-col
          cols="auto"
          md="11"
          class="px-2 mx-0 font-weight-bold"
          >Datos bancarios para cheque</v-col
        >

      </v-row>
      <v-row
          v-if="cardStatesItemCheque[tipo.TipoElementosId]"
          class="px-2 mx-0"
          style="border-bottom: 1px solid #ddd"
      >
          <v-col
            cols="auto"
            md="12"
            class="px-0 ml-0 py-0 mt-1"

          >

                <v-card-text style="">
                <v-alert
                  type="info"
                  outlined
                  dense
                  colored-border
                  color="info"
                  elevation="3"
                >
                  <template v-slot:prepend>
                    <v-icon color="info" style="font-size: 30px"
                      >mdi-information</v-icon
                    >
                  </template>
                  <p
                    class="black--text py-0 my-0 ml-3"
                    style="font-weight: 400; color: #444"
                  >
                    Por favor, verifique cuidadosamente los datos ingresados antes de
                    confirmar el pago con cheque.
                  </p>
                </v-alert>

                <!-- Tabs para Acara y MJ -->
                <v-tabs v-model="tab" background-color="white" grow @change="onTabChange">
                  <v-tab v-if="showTab(tipo.TipoElementosId,0)">Acara</v-tab>
                  <v-tab v-if="showTab(tipo.TipoElementosId,1)">MJ</v-tab>
                  <v-tab v-if="showTab(tipo.TipoElementosId,2)">ANMAC</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab"   style="min-height: 180px; ">
                  <v-tab-item v-if="showTab(tipo.TipoElementosId,0)" :key="0">
                    <v-row class="mt-4 my-0 py-0">
                       <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Nro. Cheque</label>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Metodos de Pago</label>
                      </v-col>

                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Fecha vencimiento</label>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">


                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.acara.nroCheque"
                          dense
                          outlined
                          :rules="[ $rulesNumericoMax10 ]"
                          prepend-inner-icon="mdi-numeric"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          value="Boleta banco nación"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-file-document"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.acara.vtoCheque"
                          dense
                          outlined
                          v-mask="'##/##/####'"
                          placeholder="__/__/____"
                          prepend-inner-icon="mdi-calendar"
                          :rules="[$rulesRequerido, $rulesFechaValida]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0">
                      <v-col cols="8" class="my-0 py-0">
                        <label class="importe-label">Tarjeta / Banco</label>

                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Importe</label>
                      </v-col>
                      <v-col cols="8" class="my-0 py-0">
                        <v-select
                          :items="banksAcara"
                          dense
                          outlined
                          hide-details
                          item-text="BankName"
                          item-value="BankCode"
                          v-model="tipo.Cheques.acara.banco"
                          prepend-inner-icon="mdi-bank"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">

                      <v-text-field
                          value="1"
                          v-model="tipo.Cheques.acara.importe"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-currency-usd"
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </v-tab-item>

                  <v-tab-item v-if="showTab(tipo.TipoElementosId,1)" :key="1"  style="min-height: 180px;">
                    <v-row class="mt-4 my-0 py-0">
                       <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Nro. Cheque</label>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Metodos de Pago</label>
                      </v-col>

                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Fecha vencimiento</label>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">


                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.mj.nroCheque"
                          dense
                          outlined
                          :rules="[ $rulesNumericoMax10 ]"
                          prepend-inner-icon="mdi-numeric"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          value="Boleta banco nación"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-file-document"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.mj.vtoCheque"
                          dense
                          outlined
                          v-mask="'##/##/####'"
                          placeholder="__/__/____"
                          prepend-inner-icon="mdi-calendar"
                          :rules="[$rulesRequerido, $rulesFechaValida]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0">
                      <v-col cols="8" class="my-0 py-0">
                        <label class="importe-label">Tarjeta / Banco</label>

                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Importe</label>
                      </v-col>
                      <v-col cols="8" class="my-0 py-0">
                        <v-select
                          :items="banksMj"
                          dense
                          outlined
                          hide-details
                          item-text="BankName"
                          item-value="BankCode"
                          v-model="tipo.Cheques.mj.banco"
                          prepend-inner-icon="mdi-bank"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">

                      <v-text-field
                          value="1"
                          v-model="tipo.Cheques.mj.importe"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-currency-usd"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>

                  <v-tab-item v-if="showTab(tipo.TipoElementosId,2)" :key="1"  style="min-height: 180px;">
                    <v-row class="mt-4 my-0 py-0">
                       <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Nro. Cheque</label>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Metodos de Pago</label>
                      </v-col>

                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Fecha vencimiento</label>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 py-0">


                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.anmac.nroCheque"
                          dense
                          outlined
                          :rules="[ $rulesNumericoMax10 ]"
                          prepend-inner-icon="mdi-numeric"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          value="Boleta banco nación"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-file-document"
                        ></v-text-field>
                      </v-col>
                       <v-col cols="4" class="my-0 py-0">
                        <v-text-field
                          v-model="tipo.Cheques.anmac.vtoCheque"
                          dense
                          outlined
                          v-mask="'##/##/####'"
                          placeholder="__/__/____"
                          prepend-inner-icon="mdi-calendar"
                          :rules="[$rulesRequerido, $rulesFechaValida]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="py-0">
                      <v-col cols="8" class="my-0 py-0">
                        <label class="importe-label">Tarjeta / Banco</label>

                      </v-col>
                      <v-col cols="4" class="my-0 py-0">
                        <label class="importe-label">Importe</label>
                      </v-col>
                      <v-col cols="8" class="my-0 py-0">
                        <v-select
                          :items="banksMj"
                          dense
                          outlined
                          hide-details
                          item-text="BankName"
                          item-value="BankCode"
                          v-model="tipo.Cheques.anmac.banco"
                          prepend-inner-icon="mdi-bank"
                          return-object
                        ></v-select>
                      </v-col>
                      <v-col cols="4" class="my-0 py-0">

                      <v-text-field
                          value="1"
                          v-model="tipo.Cheques.anmac.importe"
                          dense
                          outlined
                          hide-details
                          filled
                          disabled
                          prepend-inner-icon="mdi-currency-usd"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
          </v-col>
      </v-row>
    </div>
</template>

<style scoped>

.custom-btn {
  text-transform: none; /* Elimina la transformación de mayúsculas */
  font-family: inherit; /* Hereda el tipo de letra por defecto */
}

.capitalize-first::first-letter {
  text-transform: capitalize; /* Solo la primera letra en mayúscula */
}
</style>
<script>

export default {
  components: {


  },
  props: {

    tipo: {
      type: Object,
      default: false,
    },
    carritoTiposElementos: {
      type: Array,
      default: false,
    },


  },
  data() {
    return {

    tab: 0,
    banksAcara:[],
    banksMj:[],
    cardStatesItemCheque: [
      true,true,true
    ],
    };
  },
  watch: {

  },

  mounted(){

    console.log("ssss");
    this.onTabChange(0);
    this.onTabChange(1);
    this.setImportesCheques();

  },
  methods: {



    toggleCardItemCheque(indexItem) {
      // Alterna la expansión del ítem dentro de la tarjeta
      this.$set(
        this.cardStatesItemCheque,
        indexItem,
        !this.cardStatesItemCheque[indexItem]
      );
    },
    setImportesCheques(){

        //let indiceTipo = this.tipo.TipoElementosId-1;
        let indiceTipo = this.carritoTiposElementos.findIndex((tipo) => tipo.TipoElementosId === this.tipo.TipoElementosId);

        if (this.$canSap("venderElementosAnmac"))
        {
          this.carritoTiposElementos[indiceTipo].Cheques.anmac.importe = this.$formatMoney(this.$store.state.carrito.sumasPorTipo[this.tipo.TipoElementosId].subtotalDb[this.$db("ANMAC")]);

        }
        else
        {
          this.carritoTiposElementos[indiceTipo].Cheques.acara.importe = this.$formatMoney(this.$store.state.carrito.sumasPorTipo[this.tipo.TipoElementosId].subtotalDb[this.$db("ACARA")]);
          this.carritoTiposElementos[indiceTipo].Cheques.mj.importe = this.$formatMoney(this.$store.state.carrito.sumasPorTipo[this.tipo.TipoElementosId].subtotalDb[this.$db("MJ")]);

        }



    },
    onTabChange(newTab) {


      if(this.tipo.TipoElementosId ==5)
      {
        this.fetchBanks('banksAnmac');


      }
      else
      {
        this.fetchBanks('banksAcara');

        this.fetchBanks('banksMj');
      }




    },
    async fetchBanks(database) {
      try {
         await this.$axiosApi.get(database).then((response) => {

        this[database] = response.data.data.value;


        if(this.tipo.TipoElementosId ==5)
        {
          if(this.tipo.MetodoPago == "TRANSFER")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('T'));
          }
          if(this.tipo.MetodoPago == "DEPOSIT")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('D'));
          }
          if(this.tipo.MetodoPago == "BANKNACION_CHEQUE")
          {
            this[database] = this[database].filter(item => item.AliasName.includes('C'));
          }
        }

       }).catch((error) => this.$handleAxiosError(error));

      } catch (error) {
        console.error('Error fetching banks:', error);
      }
    },
    showTab(tipo,tabIndex) {
        const subtotalDb =
          this.$store.state.carrito.sumasPorTipo[tipo]
            .subtotalDb;
        if (tabIndex === 0) {
          return subtotalDb[this.$db("ACARA")] > 0;
        }

        if (tabIndex === 1) {
           return subtotalDb[this.$db("MJ")] > 0;
        }
        if (tabIndex === 2) {
          return subtotalDb[this.$db("ANMAC")] > 0;
        }
        return false;
      },

  },

};
</script>
